input, textarea, .select, .daterange-input {
	display: block;
	width: 100%;
	margin-bottom:0;
	padding: 8px 5px;
	border: none;
	border-bottom: 1px solid @light;
	-webkit-appearance: none;
	-moz-appearance: none;
	/* margin-left: -5px; */
	font-size: 18px;
	background-color: transparent;
	.serif;
	.transition;
}

label, .password-reset {
	display: block;
	font-size: 12px;
	.main-font;
	font-weight: 600;
	margin-top: 20px;
	.caps;
}

textarea {
	resize: none;

}

.form-heading {
	padding: 20px;
}

.large-input {
	font-size: 21px;
	.serif;
}

input:focus, textarea:focus {
	background: rgba(0,0,0,.2);
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

input[type="checkbox"]:focus {
	background: transparent;
}

input[type="checkbox"] {
	position: relative;
	display: inline-block;
	width: auto;
	border: none;
	width: 20px;
	height: 20px;
	padding: 0;
	margin:0 10px 0 0;
}

input[type="checkbox"]:after {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 4px;
	.icon;
	font-size: 20px;
	content: "\f401";
	.transition;
}

input[type="checkbox"]:checked:after {
	.icon;
	content: "\f3ff";
	.transition;
}

input[type="radio"]:after {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 4px;
	.icon;
	font-size: 20px;
	content: "\f401";
	.transition;
}

input[type="radio"]:checked:after {
	.icon;
	content: "\f400";
}

button {
	display: flex;
	font-size: 15px;
	align-items: center;
	justify-content: center;
	width: 100%;
	border: none;
	border-bottom: 1px solid white;
	color: @dark;
	.main-font;
	.caps;
	background: @cream;
	padding: 15px 0;
	.transition;
	-webkit-appearance: none;
	-moz-appearance: none;

	i {
		display: inline-block;
		padding-left: 10px;
		font-size: 30px;
		.transition;
	}

}

button:hover {
	border-bottom: 1px solid @dark;

	i {
		padding-left: 15px;
	}
}

a.password-reset {
	margin-left: 30px;
}

.lower-submit {
	margin: 30px auto;
}

.has-error {
	position: relative;
}

.has-error input, .has-error input:focus {
	border: 1px solid @red;
}

.has-error input {
	border: 1px solid lighten(@red, 20%);
}

.has-error input:focus {
	border: 1px solid @red;
}

.help-block {
	display: block;
	position: absolute;
	left: 0;
	bottom: -42px;
	width: 100%;
	color: white;
	padding: 0px 3px;
	font-size: 12px;
	color: @red;
	margin-bottom: 20px;
}

.select-style {
	position: relative;
    border: none;
    width: auto;
    border-radius: 0;
    overflow: hidden;
    background: transparent;
}

.select-style:before {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	top: 0;
	bottom: 0;
	.icon;
	content: "\f3d0";
	font-size: 25px;
	z-index: 1;
	.transition;
}

.select-style:hover:before {
	color: @teal;
}

.select-style select {
    padding: 8px 5px;
    width: 130%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    font-size: 18px;
    .serif;
}

.select-style select:focus {
    outline: none;
}

.select {
	position: relative;
	border-radius: 0;
}

.select-wrap {
	position: relative;
}

.select-wrap:after {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	right: 10px;
	top: 0;
	bottom: 0;
	.icon;
	content: "\f3d0";
	font-size: 20px;
	color: @dark;
}

.select:focus {
    outline: none;
    background-color: @light;
}

.big-submit {
	background: @dark;
	color: white;
	.serif;
	margin: 30px 0 0 0;
	.transition;
}

.big-submit:hover {
	background: @dark;
}

.help {
	.main-font;
	color: @red;
}

.form-section {
	padding: 20px;
	.shadow;
	margin-bottom: 30px;
}