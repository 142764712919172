@charset "UTF-8";
.main-font {
  font-family: 'Oswald', sans-serif;
}
.serif {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.caps {
  text-transform: uppercase;
}
.icon {
  font-family: "Ionicons";
}
.rowflex {
  display: flex;
}
.rowflex .columns,
.rowflex .column {
  margin: auto;
}
.blue {
  color: #2cbbe7;
}
.teal {
  color: #53c4bd;
}
.purple {
  color: #2e358f;
}
.red {
  color: #54a1cc;
}
.teal {
  color: #53c4bd;
}
.green {
  color: #66ba89;
}
.yellow {
  color: #f3ae00;
}
.orange {
  color: #ff880c;
}
.lime {
  color: #a9b500;
}
.dark {
  color: #403e43;
}
.light {
  color: #bcbab5;
}
.white {
  color: white;
}
.blue-bg {
  background: #2cbbe7;
}
.teal-bg {
  background: #53c4bd;
}
.purple-bg {
  background: #2e358f;
}
.red-bg {
  background: #54a1cc;
}
.teal-bg {
  background: #53c4bd;
}
.green-bg {
  background: #66ba89;
}
.yellow-bg {
  background: #f3ae00;
}
.orange-bg {
  background: #ff880c;
}
.lime-bg {
  background: #a9b500;
}
.dark-bg {
  background: #403e43;
}
.light-bg {
  background: #bcbab5;
}
.white-bg {
  background: white;
}
.center,
.align-center {
  text-align: center;
}
span.center {
  display: block;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.transition {
  transition: .2s linear all;
}
.transition-slow {
  transition: .5s linear all;
}
.shadow {
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
}
.box-shadow {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
hr.clearfix {
  border: none;
  opacity: 0;
}
.hidden {
  display: none;
}
.hidden.active {
  display: block;
}
.pointer {
  cursor: pointer;
}
.gradient-fade {
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f000000', endColorstr='#00000000', GradientType=1);
}
.sticky {
  position: sticky;
  top: 175px;
}
.flex-full {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.full-wrapper {
  margin: auto;
}
/**
* Windfall Framework
* ---------------
* Built with love by your friends @windfallstudio
* http://www.windfallstudio.com
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
a img {
  border: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
.pull_right {
  float: right;
}
.pull_left {
  float: left;
}
/* Base Styles */
html {
  font-size: 100%;
  line-height: 1.625em;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: #fff;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  color: #555555;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 767px) {
  body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    width: 100%;
    min-width: 0;
  }
}
html,
body {
  height: 100%;
}
.hide {
  display: none;
}
.hide.active,
.show {
  display: block;
}
.fixed {
  position: fixed;
}
.fixed.pinned {
  position: absolute;
}
@media only screen and (max-width: 768px) {
  .fixed {
    position: relative !important;
    top: auto !important;
    left: auto !important;
  }
}
.flex {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .flex {
    display: block;
  }
}
.auto {
  margin: auto;
}
.unfixed {
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  color: #444444;
  text-rendering: optimizeLegibility;
  padding-top: 0.273em;
  line-height: 1.15538em;
  padding-bottom: 0.273em;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #54a1cc;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #2b6b8f;
}
@media only screen and (max-width: 767px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-wrap: break-word;
  }
}
h1 {
  font-size: 30px;
}
h1.xlarge {
  font-size: 110px;
}
h1.xxlarge {
  font-size: 126px;
}
h1.absurd {
  font-size: 177px;
}
h2 {
  font-size: 26px;
}
h3 {
  font-size: 23px;
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 42px;
    font-size: 2.625rem;
  }
  h2 {
    font-size: 36px;
    font-size: 2.25rem;
  }
}
.subhead {
  color: #777;
  font-weight: normal;
  margin-bottom: 20px;
}
/*=====================================================
 Links & Paragraph styles
 ======================================================*/
p {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 13px;
  line-height: 1.625em;
}
p.lead {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 18px;
}
@media only screen and (max-width: 768px) {
  p {
    font-size: 17.6px;
    font-size: 1.1rem;
    line-height: 1.625em;
  }
}
a {
  color: #54a1cc;
  text-decoration: none;
  outline: 0;
  line-height: inherit;
  transition: .2s linear all;
}
a:hover {
  color: #2b6b8f;
}
/*=====================================================
 Lists
 ======================================================*/
ul,
ol {
  margin-bottom: 0.273em;
}
ul {
  list-style: none outside;
}
ol {
  list-style: decimal;
  margin-left: 30px;
}
ul.square,
ul.circle,
ul.disc {
  margin-left: 25px;
}
ul.square {
  list-style: square outside;
}
ul.circle {
  list-style: circle outside;
}
ul.disc {
  list-style: disc outside;
}
ul ul {
  margin: 4px 0 5px 25px;
}
ol ol {
  margin: 4px 0 5px 30px;
}
li {
  padding-bottom: 0.273em;
}
ul.large li {
  line-height: 21px;
}
dl dt {
  font-weight: bold;
  font-size: 16px;
  font-size: 1rem;
}
@media only screen and (max-width: 768px) {
  ul,
  ol,
  dl,
  p {
    text-align: left;
  }
}
/* Mobile */
em {
  font-style: italic;
  line-height: inherit;
}
strong {
  font-weight: 700;
  line-height: inherit;
}
small {
  font-size: 56.4%;
  line-height: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small {
  color: #777;
}
/*  Blockquotes  */
blockquote {
  line-height: 20px;
  color: #777;
  margin: 0 0 18px;
  padding: 9px 20px 0 19px;
  border-left: 5px solid #ccc;
}
blockquote p {
  line-height: 20px;
  color: #777;
}
blockquote cite {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  color: #555555;
}
blockquote cite:before {
  content: "\2014 \0020";
}
blockquote cite a {
  color: #555555;
}
blockquote cite a:visited {
  color: #555555;
}
hr {
  border: 1px solid #ccc;
  clear: both;
  margin: 16px 0 18px;
  height: 0;
}
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222;
  border-bottom: 1px solid #ccc;
  cursor: help;
}
abbr {
  text-transform: none;
}
/** Print styles.  Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/ Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com) */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  /* Black prints faster: sanbeiji.com/archives/953 */
  p a {
    color: #555555 !important;
    text-decoration: underline;
  }
  p a:visited {
    color: #555555 !important;
    text-decoration: underline;
  }
  p a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  /* css-discuss.incutio.com/wiki/Printing_Tables */
  tr,
  img {
    page-break-inside: avoid;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
/*=================================================

	+++ LE GRID +++
	A Responsive Grid -- Windfall defaults to a standard 960 grid,
	but you can change it to whatever you'd like.
 ==================================================*/
/*.container {
	padding: 0 $gutter-in-px;
}*/
.row {
  width: 100%;
  max-width: 1100px;
  min-width: 320px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.row .row,
.full-row {
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
}
/* To fix the grid into a different size, set max-width to your desired width */
.column,
.columns {
  margin-left: 2.12766%;
  float: left;
  min-height: 1px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.column:first-child,
.columns:first-child,
.alpha {
  margin-left: 0;
}
.column.omega,
.columns.omega {
  float: right;
}
/* Column Classes */
.row .one.column {
  width: 6.38298%;
}
.row .one.columns {
  width: 6.38298%;
}
.row .two.columns {
  width: 14.89362%;
}
.row .three.columns {
  width: 23.40426%;
}
.row .four.columns {
  width: 31.91489%;
}
.row .five.columns {
  width: 40.42553%;
}
.row .six.columns {
  width: 48.93617%;
}
.row .seven.columns {
  width: 57.44681%;
}
.row .eight.columns {
  width: 65.95745%;
}
.row .nine.columns {
  width: 74.46809%;
}
.row .ten.columns {
  width: 82.97872%;
}
.row .eleven.columns {
  width: 91.48936%;
}
.row .twelve.columns {
  width: 100%;
}
/* Push and Pull Classes */
.row .push_one {
  margin-left: 10.6383%;
}
.row .push_one:first-child {
  margin-left: 8.51064%;
}
.row .push_one_half:first-child {
  margin-left: 12.76596%;
}
.row .pull_one.one.column {
  margin-left: -14.89362%;
}
.row .pull_one.two.columns {
  margin-left: -23.40426%;
}
.row .pull_one.three.columns {
  margin-left: -31.91489%;
}
.row .pull_one.four.columns {
  margin-left: -40.42553%;
}
.row .pull_one.five.columns {
  margin-left: -48.93617%;
}
.row .pull_one.six.columns {
  margin-left: -57.44681%;
}
.row .pull_one.seven.columns {
  margin-left: -65.95745%;
}
.row .pull_one.eight.columns {
  margin-left: -74.46809%;
}
.row .pull_one.nine.columns {
  margin-left: -82.97872%;
}
.row .pull_one.ten.columns {
  margin-left: -91.48936%;
}
.row .push_two {
  margin-left: 19.14894%;
}
.row .push_two:first-child {
  margin-left: 17.02128%;
}
.row .pull_two.one.column {
  margin-left: -23.40426%;
}
.row .pull_two.two.columns {
  margin-left: -31.91489%;
}
.row .pull_two.three.columns {
  margin-left: -40.42553%;
}
.row .pull_two.four.columns {
  margin-left: -48.93617%;
}
.row .pull_two.five.columns {
  margin-left: -57.44681%;
}
.row .pull_two.six.columns {
  margin-left: -65.95745%;
}
.row .pull_two.seven.columns {
  margin-left: -74.46809%;
}
.row .pull_two.eight.columns {
  margin-left: -82.97872%;
}
.row .pull_two.nine.columns {
  margin-left: -91.48936%;
}
.row .pull_two.eleven.columns {
  margin-left: -108.51064%;
}
.row .push_three {
  margin-left: 27.65957%;
}
.row .push_three:first-child {
  margin-left: 25.53191%;
}
.row .pull_three.one.column {
  margin-left: -31.91489%;
}
.row .pull_three.two.columns {
  margin-left: -40.42553%;
}
.row .pull_three.three.columns {
  margin-left: -48.93617%;
}
.row .pull_three.four.columns {
  margin-left: -57.44681%;
}
.row .pull_three.five.columns {
  margin-left: -65.95745%;
}
.row .pull_three.six.columns {
  margin-left: -74.46809%;
}
.row .pull_three.seven.columns {
  margin-left: -82.97872%;
}
.row .pull_three.eight.columns {
  margin-left: -91.48936%;
}
.row .pull_three.ten.columns {
  margin-left: -108.51064%;
}
.row .pull_three.eleven.columns {
  margin-left: -117.02128%;
}
.row .push_four {
  margin-left: 36.17021%;
}
.row .push_four:first-child {
  margin-left: 34.04255%;
}
.row .pull_four.one.column {
  margin-left: -40.42553%;
}
.row .pull_four.two.columns {
  margin-left: -48.93617%;
}
.row .pull_four.three.columns {
  margin-left: -57.44681%;
}
.row .pull_four.four.columns {
  margin-left: -65.95745%;
}
.row .pull_four.five.columns {
  margin-left: -74.46809%;
}
.row .pull_four.six.columns {
  margin-left: -82.97872%;
}
.row .pull_four.seven.columns {
  margin-left: -91.48936%;
}
.row .pull_four.nine.columns {
  margin-left: -108.51064%;
}
.row .pull_four.ten.columns {
  margin-left: -117.02128%;
}
.row .pull_four.eleven.columns {
  margin-left: -125.53191%;
}
.row .push_five {
  margin-left: 44.68085%;
}
.row .push_five:first-child {
  margin-left: 42.55319%;
}
.row .pull_five.one.column {
  margin-left: -48.93617%;
}
.row .pull_five.two.columns {
  margin-left: -57.44681%;
}
.row .pull_five.three.columns {
  margin-left: -65.95745%;
}
.row .pull_five.four.columns {
  margin-left: -74.46809%;
}
.row .pull_five.five.columns {
  margin-left: -82.97872%;
}
.row .pull_five.six.columns {
  margin-left: -91.48936%;
}
.row .pull_five.eight.columns {
  margin-left: -108.51064%;
}
.row .pull_five.nine.columns {
  margin-left: -117.02128%;
}
.row .pull_five.ten.columns {
  margin-left: -125.53191%;
}
.row .pull_five.eleven.columns {
  margin-left: -134.04255%;
}
.row .push_six {
  margin-left: 53.19149%;
}
.row .push_six:first-child {
  margin-left: 51.06383%;
}
.row .pull_six.one.column {
  margin-left: -57.44681%;
}
.row .pull_six.two.columns {
  margin-left: -65.95745%;
}
.row .pull_six.three.columns {
  margin-left: -74.46809%;
}
.row .pull_six.four.columns {
  margin-left: -82.97872%;
}
.row .pull_six.five.columns {
  margin-left: -91.48936%;
}
.row .pull_six.seven.columns {
  margin-left: -108.51064%;
}
.row .pull_six.eight.columns {
  margin-left: -117.02128%;
}
.row .pull_six.nine.columns {
  margin-left: -125.53191%;
}
.row .pull_six.ten.columns {
  margin-left: -134.04255%;
}
.row .pull_six.eleven.columns {
  margin-left: -142.55319%;
}
.row .push_seven {
  margin-left: 61.70213%;
}
.row .push_seven:first-child {
  margin-left: 59.57447%;
}
.row .pull_seven.one.column {
  margin-left: -65.95745%;
}
.row .pull_seven.two.columns {
  margin-left: -74.46809%;
}
.row .pull_seven.three.columns {
  margin-left: -82.97872%;
}
.row .pull_seven.four.columns {
  margin-left: -91.48936%;
}
.row .pull_seven.six.columns {
  margin-left: -108.51064%;
}
.row .pull_seven.seven.columns {
  margin-left: -117.02128%;
}
.row .pull_seven.eight.columns {
  margin-left: -125.53191%;
}
.row .pull_seven.nine.columns {
  margin-left: -134.04255%;
}
.row .pull_seven.ten.columns {
  margin-left: -142.55319%;
}
.row .pull_seven.eleven.columns {
  margin-left: -151.06383%;
}
.row .push_eight {
  margin-left: 70.21277%;
}
.row .push_eight:first-child {
  margin-left: 68.08511%;
}
.row .pull_eight.one.column {
  margin-left: -74.46809%;
}
.row .pull_eight.two.columns {
  margin-left: -82.97872%;
}
.row .pull_eight.three.columns {
  margin-left: -91.48936%;
}
.row .pull_eight.five.columns {
  margin-left: -108.51064%;
}
.row .pull_eight.six.columns {
  margin-left: -117.02128%;
}
.row .pull_eight.seven.columns {
  margin-left: -125.53191%;
}
.row .pull_eight.eight.columns {
  margin-left: -134.04255%;
}
.row .pull_eight.nine.columns {
  margin-left: -142.55319%;
}
.row .pull_eight.ten.columns {
  margin-left: -151.06383%;
}
.row .pull_eight.eleven.columns {
  margin-left: -159.57447%;
}
.row .push_nine {
  margin-left: 78.7234%;
}
.row .push_nine:first-child {
  margin-left: 76.59574%;
}
.row .pull_nine.one.column {
  margin-left: -82.97872%;
}
.row .pull_nine.two.columns {
  margin-left: -91.48936%;
}
.row .pull_nine.four.columns {
  margin-left: -108.51064%;
}
.row .pull_nine.five.columns {
  margin-left: -117.02128%;
}
.row .pull_nine.six.columns {
  margin-left: -125.53191%;
}
.row .pull_nine.seven.columns {
  margin-left: -134.04255%;
}
.row .pull_nine.eight.columns {
  margin-left: -142.55319%;
}
.row .pull_nine.nine.columns {
  margin-left: -151.06383%;
}
.row .pull_nine.ten.columns {
  margin-left: -159.57447%;
}
.row .pull_nine.eleven.columns {
  margin-left: -168.08511%;
}
.row .push_ten {
  margin-left: 87.23404%;
}
.row .push_ten:first-child {
  margin-left: 85.10638%;
}
.row .pull_ten.one.column {
  margin-left: -91.48936%;
}
.row .pull_ten.three.columns {
  margin-left: -108.51064%;
}
.row .pull_ten.four.columns {
  margin-left: -117.02128%;
}
.row .pull_ten.five.columns {
  margin-left: -125.53191%;
}
.row .pull_ten.six.columns {
  margin-left: -134.04255%;
}
.row .pull_ten.seven.columns {
  margin-left: -142.55319%;
}
.row .pull_ten.eight.columns {
  margin-left: -151.06383%;
}
.row .pull_ten.nine.columns {
  margin-left: -159.57447%;
}
.row .pull_ten.ten.columns {
  margin-left: -168.08511%;
}
.row .pull_ten.eleven.columns {
  margin-left: -176.59574%;
}
.row .push_eleven {
  margin-left: 95.74468%;
}
.row .push_eleven:first-child {
  margin-left: 93.61702%;
}
.row .pull_eleven.two.columns {
  margin-left: -108.51064%;
}
.row .pull_eleven.three.columns {
  margin-left: -117.02128%;
}
.row .pull_eleven.four.columns {
  margin-left: -125.53191%;
}
.row .pull_eleven.five.columns {
  margin-left: -134.04255%;
}
.row .pull_eleven.six.columns {
  margin-left: -142.55319%;
}
.row .pull_eleven.seven.columns {
  margin-left: -151.06383%;
}
.row .pull_eleven.eight.columns {
  margin-left: -159.57447%;
}
.row .pull_eleven.nine.columns {
  margin-left: -168.08511%;
}
.row .pull_eleven.ten.columns {
  margin-left: -176.59574%;
}
.row .pull_eleven.eleven.columns {
  margin-left: -185.10638%;
}
/* Centered Classes */
.row .one.centered {
  margin-left: 46.80851%;
}
.row .two.centered {
  margin-left: 42.55319%;
}
.row .three.centered {
  margin-left: 38.29787%;
}
.row .four.centered {
  margin-left: 34.04255%;
}
.row .five.centered {
  margin-left: 29.78723%;
}
.row .six.centered {
  margin-left: 25.53191%;
}
.row .seven.centered {
  margin-left: 21.2766%;
}
.row .eight.centered {
  margin-left: 17.02128%;
}
.row .nine.centered {
  margin-left: 12.76596%;
}
.row .ten.centered {
  margin-left: 8.51064%;
}
.row .eleven.centered {
  margin-left: 4.25532%;
}
/* Hybrid Grid Columns */
.sixteen.colgrid .row .one.column {
  width: 4.25532%;
}
.sixteen.colgrid .row .one.columns {
  width: 4.25532%;
}
.sixteen.colgrid .row .two.columns {
  width: 10.6383%;
}
.sixteen.colgrid .row .three.columns {
  width: 17.02128%;
}
.sixteen.colgrid .row .four.columns {
  width: 23.40426%;
}
.sixteen.colgrid .row .five.columns {
  width: 29.78723%;
}
.sixteen.colgrid .row .six.columns {
  width: 36.17021%;
}
.sixteen.colgrid .row .seven.columns {
  width: 42.55319%;
}
.sixteen.colgrid .row .eight.columns {
  width: 48.93617%;
}
.sixteen.colgrid .row .nine.columns {
  width: 55.31915%;
}
.sixteen.colgrid .row .ten.columns {
  width: 61.70213%;
}
.sixteen.colgrid .row .eleven.columns {
  width: 68.08511%;
}
.sixteen.colgrid .row .twelve.columns {
  width: 74.46809%;
}
.sixteen.colgrid .row .thirteen.columns {
  width: 80.85106%;
}
.sixteen.colgrid .row .fourteen.columns {
  width: 87.23404%;
}
.sixteen.colgrid .row .fifteen.columns {
  width: 93.61702%;
}
.sixteen.colgrid .row .sixteen.columns {
  width: 100%;
}
/* Hybrid Push and Pull Classes */
.sixteen.colgrid .row .push_one {
  margin-left: 8.51064%;
}
.sixteen.colgrid .row .push_one:first-child {
  margin-left: 6.38298%;
}
.sixteen.colgrid .row .pull_one.one.column {
  margin-left: -10.6383%;
}
.sixteen.colgrid .row .pull_one.two.columns {
  margin-left: -17.02128%;
}
.sixteen.colgrid .row .pull_one.three.columns {
  margin-left: -23.40426%;
}
.sixteen.colgrid .row .pull_one.four.columns {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_one.five.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_one.six.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_one.seven.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_one.eight.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_one.nine.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_one.ten.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_one.eleven.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_one.twelve.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_one.thirteen.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_one.fourteen.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .push_two {
  margin-left: 14.89362%;
}
.sixteen.colgrid .row .push_two:first-child {
  margin-left: 12.76596%;
}
.sixteen.colgrid .row .pull_two.one.column {
  margin-left: -17.02128%;
}
.sixteen.colgrid .row .pull_two.two.columns {
  margin-left: -23.40426%;
}
.sixteen.colgrid .row .pull_two.three.columns {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_two.four.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_two.five.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_two.six.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_two.seven.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_two.eight.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_two.nine.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_two.ten.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_two.eleven.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_two.twelve.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_two.thirteen.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_two.fifteen.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .push_three {
  margin-left: 21.2766%;
}
.sixteen.colgrid .row .push_three:first-child {
  margin-left: 19.14894%;
}
.sixteen.colgrid .row .pull_three.one.column {
  margin-left: -23.40426%;
}
.sixteen.colgrid .row .pull_three.two.columns {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_three.three.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_three.four.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_three.five.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_three.six.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_three.seven.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_three.eight.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_three.nine.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_three.ten.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_three.eleven.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_three.twelve.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_three.fourteen.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_three.fifteen.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .push_four {
  margin-left: 27.65957%;
}
.sixteen.colgrid .row .push_four:first-child {
  margin-left: 25.53191%;
}
.sixteen.colgrid .row .pull_four.one.column {
  margin-left: -29.78723%;
}
.sixteen.colgrid .row .pull_four.two.columns {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_four.three.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_four.four.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_four.five.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_four.six.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_four.seven.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_four.eight.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_four.nine.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_four.ten.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_four.eleven.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_four.thirteen.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_four.fourteen.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_four.fifteen.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .push_five {
  margin-left: 34.04255%;
}
.sixteen.colgrid .row .push_five:first-child {
  margin-left: 31.91489%;
}
.sixteen.colgrid .row .pull_five.one.column {
  margin-left: -36.17021%;
}
.sixteen.colgrid .row .pull_five.two.columns {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_five.three.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_five.four.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_five.five.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_five.six.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_five.seven.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_five.eight.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_five.nine.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_five.ten.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_five.twelve.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_five.thirteen.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_five.fourteen.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_five.fifteen.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .push_six {
  margin-left: 40.42553%;
}
.sixteen.colgrid .row .push_six:first-child {
  margin-left: 38.29787%;
}
.sixteen.colgrid .row .pull_six.one.column {
  margin-left: -42.55319%;
}
.sixteen.colgrid .row .pull_six.two.columns {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_six.three.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_six.four.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_six.five.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_six.six.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_six.seven.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_six.eight.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_six.nine.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_six.eleven.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_six.twelve.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_six.thirteen.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_six.fourteen.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_six.fifteen.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .push_seven {
  margin-left: 46.80851%;
}
.sixteen.colgrid .row .push_seven:first-child {
  margin-left: 44.68085%;
}
.sixteen.colgrid .row .pull_seven.one.column {
  margin-left: -48.93617%;
}
.sixteen.colgrid .row .pull_seven.two.columns {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_seven.three.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_seven.four.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_seven.five.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_seven.six.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_seven.seven.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_seven.eight.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_seven.ten.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_seven.eleven.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_seven.twelve.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_seven.thirteen.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_seven.fourteen.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_seven.fifteen.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .push_eight {
  margin-left: 53.19149%;
}
.sixteen.colgrid .row .push_eight:first-child {
  margin-left: 51.06383%;
}
.sixteen.colgrid .row .pull_eight.one.column {
  margin-left: -55.31915%;
}
.sixteen.colgrid .row .pull_eight.two.columns {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_eight.three.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_eight.four.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_eight.five.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_eight.six.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_eight.seven.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_eight.nine.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_eight.ten.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_eight.eleven.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_eight.twelve.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_eight.thirteen.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_eight.fourteen.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_eight.fifteen.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .push_nine {
  margin-left: 59.57447%;
}
.sixteen.colgrid .row .push_nine:first-child {
  margin-left: 57.44681%;
}
.sixteen.colgrid .row .pull_nine.one.column {
  margin-left: -61.70213%;
}
.sixteen.colgrid .row .pull_nine.two.columns {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_nine.three.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_nine.four.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_nine.five.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_nine.six.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_nine.eight.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_nine.nine.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_nine.ten.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_nine.eleven.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_nine.twelve.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_nine.thirteen.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_nine.fourteen.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_nine.fifteen.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .push_ten {
  margin-left: 65.95745%;
}
.sixteen.colgrid .row .push_ten:first-child {
  margin-left: 63.82979%;
}
.sixteen.colgrid .row .pull_ten.one.column {
  margin-left: -68.08511%;
}
.sixteen.colgrid .row .pull_ten.two.columns {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_ten.three.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_ten.four.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_ten.five.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_ten.seven.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_ten.eight.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_ten.nine.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_ten.ten.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_ten.eleven.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_ten.twelve.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_ten.thirteen.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_ten.fourteen.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_ten.fifteen.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .push_eleven {
  margin-left: 72.34043%;
}
.sixteen.colgrid .row .push_eleven:first-child {
  margin-left: 70.21277%;
}
.sixteen.colgrid .row .pull_eleven.one.column {
  margin-left: -74.46809%;
}
.sixteen.colgrid .row .pull_eleven.two.columns {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_eleven.three.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_eleven.four.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_eleven.six.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_eleven.seven.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_eleven.eight.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_eleven.nine.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_eleven.ten.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_eleven.eleven.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_eleven.twelve.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_eleven.thirteen.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_eleven.fourteen.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_eleven.fifteen.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .push_twelve {
  margin-left: 78.7234%;
}
.sixteen.colgrid .row .push_twelve:first-child {
  margin-left: 76.59574%;
}
.sixteen.colgrid .row .pull_twelve.one.column {
  margin-left: -80.85106%;
}
.sixteen.colgrid .row .pull_twelve.two.columns {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_twelve.three.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_twelve.five.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_twelve.six.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_twelve.seven.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_twelve.eight.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_twelve.nine.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_twelve.ten.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_twelve.eleven.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_twelve.twelve.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_twelve.thirteen.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_twelve.fourteen.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_twelve.fifteen.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .push_thirteen {
  margin-left: 85.10638%;
}
.sixteen.colgrid .row .push_thirteen:first-child {
  margin-left: 82.97872%;
}
.sixteen.colgrid .row .pull_thirteen.one.column {
  margin-left: -87.23404%;
}
.sixteen.colgrid .row .pull_thirteen.two.columns {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_thirteen.four.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_thirteen.five.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_thirteen.six.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_thirteen.seven.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_thirteen.eight.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_thirteen.nine.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_thirteen.ten.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_thirteen.eleven.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_thirteen.twelve.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_thirteen.thirteen.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_thirteen.fourteen.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .pull_thirteen.fifteen.columns {
  margin-left: -176.59574%;
}
.sixteen.colgrid .row .push_fourteen {
  margin-left: 91.48936%;
}
.sixteen.colgrid .row .push_fourteen:first-child {
  margin-left: 89.3617%;
}
.sixteen.colgrid .row .pull_fourteen.one.column {
  margin-left: -93.61702%;
}
.sixteen.colgrid .row .pull_fourteen.three.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_fourteen.four.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_fourteen.five.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_fourteen.six.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_fourteen.seven.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_fourteen.eight.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_fourteen.nine.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_fourteen.ten.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_fourteen.eleven.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_fourteen.twelve.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_fourteen.thirteen.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .pull_fourteen.fourteen.columns {
  margin-left: -176.59574%;
}
.sixteen.colgrid .row .pull_fourteen.fifteen.columns {
  margin-left: -182.97872%;
}
.sixteen.colgrid .row .push_fifteen {
  margin-left: 97.87234%;
}
.sixteen.colgrid .row .push_fifteen:first-child {
  margin-left: 95.74468%;
}
.sixteen.colgrid .row .pull_fifteen.two.columns {
  margin-left: -106.38298%;
}
.sixteen.colgrid .row .pull_fifteen.three.columns {
  margin-left: -112.76596%;
}
.sixteen.colgrid .row .pull_fifteen.four.columns {
  margin-left: -119.14894%;
}
.sixteen.colgrid .row .pull_fifteen.five.columns {
  margin-left: -125.53191%;
}
.sixteen.colgrid .row .pull_fifteen.six.columns {
  margin-left: -131.91489%;
}
.sixteen.colgrid .row .pull_fifteen.seven.columns {
  margin-left: -138.29787%;
}
.sixteen.colgrid .row .pull_fifteen.eight.columns {
  margin-left: -144.68085%;
}
.sixteen.colgrid .row .pull_fifteen.nine.columns {
  margin-left: -151.06383%;
}
.sixteen.colgrid .row .pull_fifteen.ten.columns {
  margin-left: -157.44681%;
}
.sixteen.colgrid .row .pull_fifteen.eleven.columns {
  margin-left: -163.82979%;
}
.sixteen.colgrid .row .pull_fifteen.twelve.columns {
  margin-left: -170.21277%;
}
.sixteen.colgrid .row .pull_fifteen.thirteen.columns {
  margin-left: -176.59574%;
}
.sixteen.colgrid .row .pull_fifteen.fourteen.columns {
  margin-left: -182.97872%;
}
.sixteen.colgrid .row .pull_fifteen.fifteen.columns {
  margin-left: -189.3617%;
}
.row .pull_one.one.column:first-child,
.row .pull_one.two.columns:first-child,
.row .pull_one.three.columns:first-child,
.row .pull_one.four.columns:first-child,
.row .pull_one.five.columns:first-child,
.row .pull_one.six.columns:first-child,
.row .pull_one.seven.columns:first-child,
.row .pull_one.eight.columns:first-child,
.row .pull_one.nine.columns:first-child,
.row .pull_one.ten.columns:first-child,
.row .pull_two.one.column:first-child,
.row .pull_two.two.columns:first-child,
.row .pull_two.three.columns:first-child,
.row .pull_two.four.columns:first-child,
.row .pull_two.five.columns:first-child,
.row .pull_two.six.columns:first-child,
.row .pull_two.seven.columns:first-child,
.row .pull_two.eight.columns:first-child,
.row .pull_two.nine.columns:first-child,
.row .pull_two.eleven.columns:first-child,
.row .pull_three.one.column:first-child,
.row .pull_three.two.columns:first-child,
.row .pull_three.three.columns:first-child,
.row .pull_three.four.columns:first-child,
.row .pull_three.five.columns:first-child,
.row .pull_three.six.columns:first-child,
.row .pull_three.seven.columns:first-child,
.row .pull_three.eight.columns:first-child,
.row .pull_three.ten.columns:first-child,
.row .pull_three.eleven.columns:first-child,
.row .pull_four.one.column:first-child,
.row .pull_four.two.columns:first-child,
.row .pull_four.three.columns:first-child,
.row .pull_four.four.columns:first-child,
.row .pull_four.five.columns:first-child,
.row .pull_four.six.columns:first-child,
.row .pull_four.seven.columns:first-child,
.row .pull_four.nine.columns:first-child,
.row .pull_four.ten.columns:first-child,
.row .pull_four.eleven.columns:first-child,
.row .pull_five.one.column:first-child,
.row .pull_five.two.columns:first-child,
.row .pull_five.three.columns:first-child,
.row .pull_five.four.columns:first-child,
.row .pull_five.five.columns:first-child,
.row .pull_five.six.columns:first-child,
.row .pull_five.eight.columns:first-child,
.row .pull_five.nine.columns:first-child,
.row .pull_five.ten.columns:first-child,
.row .pull_five.eleven.columns:first-child,
.row .pull_six.one.column:first-child,
.row .pull_six.two.columns:first-child,
.row .pull_six.three.columns:first-child,
.row .pull_six.four.columns:first-child,
.row .pull_six.five.columns:first-child,
.row .pull_six.seven.columns:first-child,
.row .pull_six.eight.columns:first-child,
.row .pull_six.nine.columns:first-child,
.row .pull_six.ten.columns:first-child,
.row .pull_six.eleven.columns:first-child,
.row .pull_seven.one.column:first-child,
.row .pull_seven.two.columns:first-child,
.row .pull_seven.three.columns:first-child,
.row .pull_seven.four.columns:first-child,
.row .pull_seven.six.columns:first-child,
.row .pull_seven.seven.columns:first-child,
.row .pull_seven.eight.columns:first-child,
.row .pull_seven.nine.columns:first-child,
.row .pull_seven.ten.columns:first-child,
.row .pull_seven.eleven.columns:first-child,
.row .pull_eight.one.column:first-child,
.row .pull_eight.two.columns:first-child,
.row .pull_eight.three.columns:first-child,
.row .pull_eight.five.columns:first-child,
.row .pull_eight.six.columns:first-child,
.row .pull_eight.seven.columns:first-child,
.row .pull_eight.eight.columns:first-child,
.row .pull_eight.nine.columns:first-child,
.row .pull_eight.ten.columns:first-child,
.row .pull_eight.eleven.columns:first-child,
.row .pull_nine.one.column:first-child,
.row .pull_nine.two.columns:first-child,
.row .pull_nine.four.columns:first-child,
.row .pull_nine.five.columns:first-child,
.row .pull_nine.six.columns:first-child,
.row .pull_nine.seven.columns:first-child,
.row .pull_nine.eight.columns:first-child,
.row .pull_nine.nine.columns:first-child,
.row .pull_nine.ten.columns:first-child,
.row .pull_nine.eleven.columns:first-child,
.row .pull_ten.one.column:first-child,
.row .pull_ten.three.columns:first-child,
.row .pull_ten.four.columns:first-child,
.row .pull_ten.five.columns:first-child,
.row .pull_ten.six.columns:first-child,
.row .pull_ten.seven.columns:first-child,
.row .pull_ten.eight.columns:first-child,
.row .pull_ten.nine.columns:first-child,
.row .pull_ten.ten.columns:first-child,
.row .pull_ten.eleven.columns:first-child,
.row .pull_eleven.two.columns:first-child,
.row .pull_eleven.three.columns:first-child,
.row .pull_eleven.four.columns:first-child,
.row .pull_eleven.five.columns:first-child,
.row .pull_eleven.six.columns:first-child,
.row .pull_eleven.seven.columns:first-child,
.row .pull_eleven.eight.columns:first-child,
.row .pull_eleven.nine.columns:first-child,
.row .pull_eleven.ten.columns:first-child,
.row .pull_eleven.eleven.columns:first-child,
.sixteen.colgrid .row .pull_one.one.column:first-child,
.sixteen.colgrid .row .pull_one.two.columns:first-child,
.sixteen.colgrid .row .pull_one.three.columns:first-child,
.sixteen.colgrid .row .pull_one.four.columns:first-child,
.sixteen.colgrid .row .pull_one.five.columns:first-child,
.sixteen.colgrid .row .pull_one.six.columns:first-child,
.sixteen.colgrid .row .pull_one.seven.columns:first-child,
.sixteen.colgrid .row .pull_one.eight.columns:first-child,
.sixteen.colgrid .row .pull_one.nine.columns:first-child,
.sixteen.colgrid .row .pull_one.ten.columns:first-child,
.sixteen.colgrid .row .pull_one.eleven.columns:first-child,
.sixteen.colgrid .row .pull_one.twelve.columns:first-child,
.sixteen.colgrid .row .pull_one.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_one.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_two.one.column:first-child,
.sixteen.colgrid .row .pull_two.two.columns:first-child,
.sixteen.colgrid .row .pull_two.three.columns:first-child,
.sixteen.colgrid .row .pull_two.four.columns:first-child,
.sixteen.colgrid .row .pull_two.five.columns:first-child,
.sixteen.colgrid .row .pull_two.six.columns:first-child,
.sixteen.colgrid .row .pull_two.seven.columns:first-child,
.sixteen.colgrid .row .pull_two.eight.columns:first-child,
.sixteen.colgrid .row .pull_two.nine.columns:first-child,
.sixteen.colgrid .row .pull_two.ten.columns:first-child,
.sixteen.colgrid .row .pull_two.eleven.columns:first-child,
.sixteen.colgrid .row .pull_two.twelve.columns:first-child,
.sixteen.colgrid .row .pull_two.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_two.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_three.one.column:first-child,
.sixteen.colgrid .row .pull_three.two.columns:first-child,
.sixteen.colgrid .row .pull_three.three.columns:first-child,
.sixteen.colgrid .row .pull_three.four.columns:first-child,
.sixteen.colgrid .row .pull_three.five.columns:first-child,
.sixteen.colgrid .row .pull_three.six.columns:first-child,
.sixteen.colgrid .row .pull_three.seven.columns:first-child,
.sixteen.colgrid .row .pull_three.eight.columns:first-child,
.sixteen.colgrid .row .pull_three.nine.columns:first-child,
.sixteen.colgrid .row .pull_three.ten.columns:first-child,
.sixteen.colgrid .row .pull_three.eleven.columns:first-child,
.sixteen.colgrid .row .pull_three.twelve.columns:first-child,
.sixteen.colgrid .row .pull_three.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_three.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_four.one.column:first-child,
.sixteen.colgrid .row .pull_four.two.columns:first-child,
.sixteen.colgrid .row .pull_four.three.columns:first-child,
.sixteen.colgrid .row .pull_four.four.columns:first-child,
.sixteen.colgrid .row .pull_four.five.columns:first-child,
.sixteen.colgrid .row .pull_four.six.columns:first-child,
.sixteen.colgrid .row .pull_four.seven.columns:first-child,
.sixteen.colgrid .row .pull_four.eight.columns:first-child,
.sixteen.colgrid .row .pull_four.nine.columns:first-child,
.sixteen.colgrid .row .pull_four.ten.columns:first-child,
.sixteen.colgrid .row .pull_four.eleven.columns:first-child,
.sixteen.colgrid .row .pull_four.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_four.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_four.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_five.one.column:first-child,
.sixteen.colgrid .row .pull_five.two.columns:first-child,
.sixteen.colgrid .row .pull_five.three.columns:first-child,
.sixteen.colgrid .row .pull_five.four.columns:first-child,
.sixteen.colgrid .row .pull_five.five.columns:first-child,
.sixteen.colgrid .row .pull_five.six.columns:first-child,
.sixteen.colgrid .row .pull_five.seven.columns:first-child,
.sixteen.colgrid .row .pull_five.eight.columns:first-child,
.sixteen.colgrid .row .pull_five.nine.columns:first-child,
.sixteen.colgrid .row .pull_five.ten.columns:first-child,
.sixteen.colgrid .row .pull_five.twelve.columns:first-child,
.sixteen.colgrid .row .pull_five.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_five.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_five.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_six.one.column:first-child,
.sixteen.colgrid .row .pull_six.two.columns:first-child,
.sixteen.colgrid .row .pull_six.three.columns:first-child,
.sixteen.colgrid .row .pull_six.four.columns:first-child,
.sixteen.colgrid .row .pull_six.five.columns:first-child,
.sixteen.colgrid .row .pull_six.six.columns:first-child,
.sixteen.colgrid .row .pull_six.seven.columns:first-child,
.sixteen.colgrid .row .pull_six.eight.columns:first-child,
.sixteen.colgrid .row .pull_six.nine.columns:first-child,
.sixteen.colgrid .row .pull_six.eleven.columns:first-child,
.sixteen.colgrid .row .pull_six.twelve.columns:first-child,
.sixteen.colgrid .row .pull_six.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_six.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_six.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_seven.one.column:first-child,
.sixteen.colgrid .row .pull_seven.two.columns:first-child,
.sixteen.colgrid .row .pull_seven.three.columns:first-child,
.sixteen.colgrid .row .pull_seven.four.columns:first-child,
.sixteen.colgrid .row .pull_seven.five.columns:first-child,
.sixteen.colgrid .row .pull_seven.six.columns:first-child,
.sixteen.colgrid .row .pull_seven.seven.columns:first-child,
.sixteen.colgrid .row .pull_seven.eight.columns:first-child,
.sixteen.colgrid .row .pull_seven.ten.columns:first-child,
.sixteen.colgrid .row .pull_seven.eleven.columns:first-child,
.sixteen.colgrid .row .pull_seven.twelve.columns:first-child,
.sixteen.colgrid .row .pull_seven.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_seven.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_seven.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_eight.one.column:first-child,
.sixteen.colgrid .row .pull_eight.two.columns:first-child,
.sixteen.colgrid .row .pull_eight.three.columns:first-child,
.sixteen.colgrid .row .pull_eight.four.columns:first-child,
.sixteen.colgrid .row .pull_eight.five.columns:first-child,
.sixteen.colgrid .row .pull_eight.six.columns:first-child,
.sixteen.colgrid .row .pull_eight.seven.columns:first-child,
.sixteen.colgrid .row .pull_eight.nine.columns:first-child,
.sixteen.colgrid .row .pull_eight.ten.columns:first-child,
.sixteen.colgrid .row .pull_eight.eleven.columns:first-child,
.sixteen.colgrid .row .pull_eight.twelve.columns:first-child,
.sixteen.colgrid .row .pull_eight.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_eight.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_eight.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_nine.one.column:first-child,
.sixteen.colgrid .row .pull_nine.two.columns:first-child,
.sixteen.colgrid .row .pull_nine.three.columns:first-child,
.sixteen.colgrid .row .pull_nine.four.columns:first-child,
.sixteen.colgrid .row .pull_nine.five.columns:first-child,
.sixteen.colgrid .row .pull_nine.six.columns:first-child,
.sixteen.colgrid .row .pull_nine.eight.columns:first-child,
.sixteen.colgrid .row .pull_nine.nine.columns:first-child,
.sixteen.colgrid .row .pull_nine.ten.columns:first-child,
.sixteen.colgrid .row .pull_nine.eleven.columns:first-child,
.sixteen.colgrid .row .pull_nine.twelve.columns:first-child,
.sixteen.colgrid .row .pull_nine.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_nine.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_nine.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_ten.one.column:first-child,
.sixteen.colgrid .row .pull_ten.two.columns:first-child,
.sixteen.colgrid .row .pull_ten.three.columns:first-child,
.sixteen.colgrid .row .pull_ten.four.columns:first-child,
.sixteen.colgrid .row .pull_ten.five.columns:first-child,
.sixteen.colgrid .row .pull_ten.seven.columns:first-child,
.sixteen.colgrid .row .pull_ten.eight.columns:first-child,
.sixteen.colgrid .row .pull_ten.nine.columns:first-child,
.sixteen.colgrid .row .pull_ten.ten.columns:first-child,
.sixteen.colgrid .row .pull_ten.eleven.columns:first-child,
.sixteen.colgrid .row .pull_ten.twelve.columns:first-child,
.sixteen.colgrid .row .pull_ten.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_ten.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_ten.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_eleven.one.column:first-child,
.sixteen.colgrid .row .pull_eleven.two.columns:first-child,
.sixteen.colgrid .row .pull_eleven.three.columns:first-child,
.sixteen.colgrid .row .pull_eleven.four.columns:first-child,
.sixteen.colgrid .row .pull_eleven.six.columns:first-child,
.sixteen.colgrid .row .pull_eleven.seven.columns:first-child,
.sixteen.colgrid .row .pull_eleven.eight.columns:first-child,
.sixteen.colgrid .row .pull_eleven.nine.columns:first-child,
.sixteen.colgrid .row .pull_eleven.ten.columns:first-child,
.sixteen.colgrid .row .pull_eleven.eleven.columns:first-child,
.sixteen.colgrid .row .pull_eleven.twelve.columns:first-child,
.sixteen.colgrid .row .pull_eleven.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_eleven.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_eleven.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_twelve.one.column:first-child,
.sixteen.colgrid .row .pull_twelve.two.columns:first-child,
.sixteen.colgrid .row .pull_twelve.three.columns:first-child,
.sixteen.colgrid .row .pull_twelve.five.columns:first-child,
.sixteen.colgrid .row .pull_twelve.six.columns:first-child,
.sixteen.colgrid .row .pull_twelve.seven.columns:first-child,
.sixteen.colgrid .row .pull_twelve.eight.columns:first-child,
.sixteen.colgrid .row .pull_twelve.nine.columns:first-child,
.sixteen.colgrid .row .pull_twelve.ten.columns:first-child,
.sixteen.colgrid .row .pull_twelve.eleven.columns:first-child,
.sixteen.colgrid .row .pull_twelve.twelve.columns:first-child,
.sixteen.colgrid .row .pull_twelve.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_twelve.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_twelve.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.one.column:first-child,
.sixteen.colgrid .row .pull_thirteen.two.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.four.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.five.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.six.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.seven.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.eight.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.nine.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.ten.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.eleven.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.twelve.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_thirteen.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.one.column:first-child,
.sixteen.colgrid .row .pull_fourteen.three.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.four.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.five.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.six.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.seven.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.eight.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.nine.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.ten.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.eleven.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.twelve.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_fourteen.fifteen.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.two.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.three.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.four.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.five.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.six.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.seven.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.eight.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.nine.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.ten.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.eleven.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.twelve.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.thirteen.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.fourteen.columns:first-child,
.sixteen.colgrid .row .pull_fifteen.fifteen.columns:first-child {
  margin-left: 0;
}
.row .pull_one.eleven.columns,
.row .pull_two.ten.columns,
.row .pull_three.nine.columns,
.row .pull_four.eight.columns,
.row .pull_five.seven.columns,
.row .pull_six.six.columns,
.row .pull_seven.five.columns,
.row .pull_eight.four.columns,
.row .pull_nine.three.columns,
.row .pull_ten.two.columns,
.row .pull_eleven.one.columns,
.sixteen.colgrid .row .pull_one.fifteen.columns,
.sixteen.colgrid .row .pull_two.fourteen.columns,
.sixteen.colgrid .row .pull_three.thirteen.columns,
.sixteen.colgrid .row .pull_four.twelve.columns,
.sixteen.colgrid .row .pull_five.eleven.columns,
.sixteen.colgrid .row .pull_six.ten.columns,
.sixteen.colgrid .row .pull_seven.nine.columns,
.sixteen.colgrid .row .pull_eight.eight.columns,
.sixteen.colgrid .row .pull_nine.seven.columns,
.sixteen.colgrid .row .pull_ten.six.columns,
.sixteen.colgrid .row .pull_eleven.five.columns,
.sixteen.colgrid .row .pull_twelve.four.columns,
.sixteen.colgrid .row .pull_thirteen.three.columns,
.sixteen.colgrid .row .pull_fourteen.two.columns,
.sixteen.colgrid .row .pull_fifteen.one.columns {
  margin-left: -100%;
}
/* Hybrid Centered Classes */
.sixteen.colgrid .row .one.centered {
  margin-left: 47.87234%;
}
.sixteen.colgrid .row .two.centered {
  margin-left: 44.68085%;
}
.sixteen.colgrid .row .three.centered {
  margin-left: 41.48936%;
}
.sixteen.colgrid .row .four.centered {
  margin-left: 38.29787%;
}
.sixteen.colgrid .row .five.centered {
  margin-left: 35.10638%;
}
.sixteen.colgrid .row .six.centered {
  margin-left: 31.91489%;
}
.sixteen.colgrid .row .seven.centered {
  margin-left: 28.7234%;
}
.sixteen.colgrid .row .eight.centered {
  margin-left: 25.53191%;
}
.sixteen.colgrid .row .nine.centered {
  margin-left: 22.34043%;
}
.sixteen.colgrid .row .ten.centered {
  margin-left: 19.14894%;
}
.sixteen.colgrid .row .eleven.centered {
  margin-left: 15.95745%;
}
.sixteen.colgrid .row .twelve.centered {
  margin-left: 12.76596%;
}
.sixteen.colgrid .row .thirteen.centered {
  margin-left: 9.57447%;
}
.sixteen.colgrid .row .fourteen.centered {
  margin-left: 6.38298%;
}
.sixteen.colgrid .row .fifteen.centered {
  margin-left: 3.19149%;
}
img,
object,
embed {
  max-width: 100%;
  height: auto;
}
img {
  -ms-interpolation-mode: bicubic;
}
#map_canvas img,
.map_canvas img {
  max-width: none !important;
}
/* Tile Grid */
.tiles {
  display: block;
}
.tiles > li,
.tiles > .tile {
  display: block;
  height: auto;
  float: left;
  padding-bottom: 0;
}
.tiles.two_up {
  margin-left: -4%;
}
.tiles.two_up > li,
.tiles.two_up > .tile {
  margin-left: 4%;
  width: 46%;
}
.tiles.three_up,
.tiles.four_up {
  margin-left: -2%;
}
.tiles.three_up > li,
.tiles.three_up > .tile {
  margin-left: 2%;
  width: 31.3%;
}
.tiles.four_up > li,
.tiles.four_up > .tile {
  margin-left: 2%;
  width: 23%;
}
.tiles.five_up {
  margin-left: -1.5%;
}
.tiles.five_up > li,
.tiles.five_up > .tile {
  margin-left: 1.5%;
  width: 18.5%;
}
.tiles:after {
  content: “”;
  display: table;
  clear: both;
}
/* Nicolas Gallagher's micro clearfix */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.row {
  *zoom: 1;
}
.row:before,
.row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}
.valign:before {
  content: ' ';
  display: inline-block;
  height: 400px;
  vertical-align: middle;
  margin-right: -0.25em;
}
.valign > div,
.valign > article,
.valign > section,
.valign > figure {
  display: inline-block;
  vertical-align: middle;
}
/* Mobile */
@media only screen and (max-width: 767px) {
  body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    width: 100%;
    min-width: 0;
  }
  .container {
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .row {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .row .row .column,
  .row .row .columns {
    padding: 0;
  }
  .row .centered {
    margin-left: 0 !important;
  }
  .column,
  .columns {
    width: auto !important;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .column:last-child,
  .columns:last-child {
    margin-right: 0;
    float: none;
  }
  [class*="column"] + [class*="column"]:last-child {
    float: none;
  }
  [class*="column"]:before {
    display: table;
  }
  [class*="column"]:after {
    display: table;
    clear: both;
  }
  [class^="push_"],
  [class*="push_"],
  [class^="pull_"],
  [class*="pull_"] {
    margin-left: 0 !important;
  }
}
/* SHAME */
.ie8 .xxwide,
.ie8 .xwide,
.ie8 .wide,
.ie8 .normal,
.ie8 .narrow,
.ie8 .xnarrow {
  display: inline;
}
.ie8 .xxwide + input,
.ie8 .xwide + input,
.ie8 .wide + input,
.ie8 .normal + input,
.ie8 .narrow + input,
.ie8 .xnarrow + input {
  display: inline;
  margin: 0 0 0 -0.25em;
}
.ie8 .ttip:before,
.ie8 .ttip:after {
  display: none;
}
.ie8 .ttip:hover:before,
.ie8 .ttip:hover:after {
  display: block;
}
.ie9 .radio.checked i,
.ie9 .checkbox.checked i {
  top: 0;
}
input,
textarea,
.select,
.daterange-input {
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding: 8px 5px;
  border: none;
  border-bottom: 1px solid #bcbab5;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* margin-left: -5px; */
  font-size: 18px;
  background-color: transparent;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  transition: .2s linear all;
}
label,
.password-reset {
  display: block;
  font-size: 12px;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}
textarea {
  resize: none;
}
.form-heading {
  padding: 20px;
}
.large-input {
  font-size: 21px;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
input:focus,
textarea:focus {
  background: rgba(0, 0, 0, 0.2);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="checkbox"]:focus {
  background: transparent;
}
input[type="checkbox"] {
  position: relative;
  display: inline-block;
  width: auto;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 10px 0 0;
}
input[type="checkbox"]:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 4px;
  font-family: "Ionicons";
  font-size: 20px;
  content: "\f401";
  transition: .2s linear all;
}
input[type="checkbox"]:checked:after {
  font-family: "Ionicons";
  content: "\f3ff";
  transition: .2s linear all;
}
input[type="radio"]:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 4px;
  font-family: "Ionicons";
  font-size: 20px;
  content: "\f401";
  transition: .2s linear all;
}
input[type="radio"]:checked:after {
  font-family: "Ionicons";
  content: "\f400";
}
button {
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
  color: #403e43;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  background: #fcfaf7;
  padding: 15px 0;
  transition: .2s linear all;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button i {
  display: inline-block;
  padding-left: 10px;
  font-size: 30px;
  transition: .2s linear all;
}
button:hover {
  border-bottom: 1px solid #403e43;
}
button:hover i {
  padding-left: 15px;
}
a.password-reset {
  margin-left: 30px;
}
.lower-submit {
  margin: 30px auto;
}
.has-error {
  position: relative;
}
.has-error input,
.has-error input:focus {
  border: 1px solid #54a1cc;
}
.has-error input {
  border: 1px solid #a3cce3;
}
.has-error input:focus {
  border: 1px solid #54a1cc;
}
.help-block {
  display: block;
  position: absolute;
  left: 0;
  bottom: -42px;
  width: 100%;
  color: white;
  padding: 0px 3px;
  font-size: 12px;
  color: #54a1cc;
  margin-bottom: 20px;
}
.select-style {
  position: relative;
  border: none;
  width: auto;
  border-radius: 0;
  overflow: hidden;
  background: transparent;
}
.select-style:before {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  bottom: 0;
  font-family: "Ionicons";
  content: "\f3d0";
  font-size: 25px;
  z-index: 1;
  transition: .2s linear all;
}
.select-style:hover:before {
  color: #53c4bd;
}
.select-style select {
  padding: 8px 5px;
  width: 130%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  font-size: 18px;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.select-style select:focus {
  outline: none;
}
.select {
  position: relative;
  border-radius: 0;
}
.select-wrap {
  position: relative;
}
.select-wrap:after {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 0;
  bottom: 0;
  font-family: "Ionicons";
  content: "\f3d0";
  font-size: 20px;
  color: #403e43;
}
.select:focus {
  outline: none;
  background-color: #bcbab5;
}
.big-submit {
  background: #403e43;
  color: white;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  margin: 30px 0 0 0;
  transition: .2s linear all;
}
.big-submit:hover {
  background: #403e43;
}
.help {
  font-family: 'Oswald', sans-serif;
  color: #54a1cc;
}
.form-section {
  padding: 20px;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
  margin-bottom: 30px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 50px auto;
  border: 1px solid #bcbab5;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: #f5eee4;
}
th {
  color: #403e43;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  font-size: 13px;
}
td,
th {
  padding: 10px;
  border: none;
  text-align: left;
  font-size: 13px;
}
thead tr {
  background-color: #403e43;
  color: #fcfaf7;
  text-transform: uppercase;
  font-size: 10px !important;
}
thead tr th {
  background-color: #403e43;
  color: #fcfaf7 !important;
  font-size: 10px !important;
  letter-spacing: 1px;
}
/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    width: 100%;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    border: 1px solid #bcbab5;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #403e43;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);
    color: #403e43;
    font-weight: bold;
  }
}
body {
  background: #fcfaf7;
  padding-top: 120px;
}
header {
  position: fixed;
  align-items: center;
  justify-content: center;
  background: white;
  top: 0;
  height: 90px;
  left: 90px;
  padding: 0 20px;
  background: #fcfaf7;
}
header a {
  display: inline-block;
  padding: auto 5px;
}
.site-dropdown h1 {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 90px;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.site-dropdown h1 i {
  padding-left: 20px;
}
.site-list {
  display: none;
  position: absolute;
  top: 90px;
  background: #403e43;
  padding: 20px;
  left: -90px;
  right: 0;
  padding-left: 20px;
  border-left: 90px solid #54a1cc;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.site-list a {
  color: #fcfaf7;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 45px;
  right: 45px;
  height: 75px;
  width: 75px;
  background: #54a1cc;
  border-radius: 75px;
  transition: .2s linear all;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 20;
}
.circle i {
  font-size: 50px;
  color: white;
}
.rotated {
  transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  /* IE 9 */
  -moz-transform: rotate(45deg) !important;
  /* Firefox */
  -webkit-transform: rotate(45deg) !important;
  /* Safari and Chrome */
  -o-transform: rotate(45deg) !important;
  /* Opera */
}
span.plus-icon.rotated {
  transform: rotate(0) !important;
  -ms-transform: rotate(0) !important;
  /* IE 9 */
  -moz-transform: rotate(0) !important;
  /* Firefox */
  -webkit-transform: rotate(0) !important;
  /* Safari and Chrome */
  -o-transform: rotate(0) !important;
  /* Opera */
}
.circle.rotated {
  box-shadow: none;
  background: #403e43;
}
.stat {
  border-right: 1px solid #403e43;
  padding-left: 30px;
  padding-right: 20px;
}
.stat a {
  display: block;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  text-align: right;
}
.stat:last-child {
  border: none;
}
.stat:first-child {
  padding-left: 0;
}
.stat-label {
  text-transform: uppercase;
  color: #54a1cc;
}
.stat-number {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  font-size: 42px;
}
.add-menu {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 0;
  padding: 60px 30px 120px 20px;
  background: #403e43;
}
.add-menu .opt-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #54a1cc;
  color: #fcfaf7;
  padding: 8px 20px;
  text-transform: uppercase;
}
.add-menu a {
  color: #fcfaf7;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.big-icon {
  display: block;
  font-size: 24px;
  margin-top: 30px;
}
.padded {
  padding: 40px;
}
.margin-bottom {
  margin-bottom: 20px;
}
.logo {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  top: 0;
  left: 0;
  background: #54a1cc;
}
.logo span {
  font-size: 21px;
  color: white;
}
.logo img {
  width: 60%;
  margin: auto;
}
.login-logo {
  text-align: center;
}
.login-logo img {
  max-width: 40%;
  margin: auto;
}
.menu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcfaf7;
  border-right: 5px solid #54a1cc;
  top: 0;
  height: 90px;
  width: 90px;
  right: 0;
  transition: .2s linear all;
  cursor: pointer;
  z-index: 2;
}
.menu i {
  font-size: 24px;
  transition: .2s linear all;
}
.menu .open {
  display: block;
}
.menu .close {
  display: none;
}
.menu:hover,
.menu.active {
  background: #54a1cc;
}
.menu:hover i,
.menu.active i {
  color: #fcfaf7;
}
.menu.active {
  width: 300px;
  background: #54a1cc;
}
.menu.active .open {
  display: none;
}
.menu.active .close {
  display: block;
}
.menu-dropdown {
  display: none;
  position: fixed;
  background: #403e43;
  top: 90px;
  width: 300px;
  padding: 40px;
  right: 0;
  z-index: 2;
  transition: .2s linear all;
}
.menu-dropdown a {
  position: relative;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  color: white;
  transition: .2s linear all;
}
.menu-dropdown a:hover {
  padding-left: 20px;
}
.menu-dropdown a:before {
  position: absolute;
  display: block;
  align-items: center;
  justify-content: center;
  left: 0;
  top: -2px;
  font-size: 24px;
  bottom: 0;
  content: "\f462";
  font-family: "Ionicons";
  opacity: 0;
  transition: .3s linear all;
}
.menu-dropdown a:hover:before {
  opacity: 1;
}
.menu-dropdown.active {
  display: block;
}
.flex-full {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.full-wrapper {
  margin: auto;
}
h2,
h3 {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.entry {
  display: none;
  margin: auto;
  max-width: 1200px;
  border-radius: 0 0 15px 15px;
  background: #fcfaf7;
  padding: 40px;
  border: 1px solid #fcfaf7;
  margin-bottom: 70px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  transition: .2s linear all;
}
.entry.active {
  display: block;
}
.task {
  margin-top: 20px;
}
.list-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #bcbab5;
}
.list {
  border-bottom: 1px solid #bcbab5;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.list a {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.task-list {
  border-bottom: 1px solid #bcbab5;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.task-list a {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.task-list a {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  color: #403e43;
}
.padding-top-button {
  display: block;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  padding-top: 30px;
}
.duration-old span {
  display: block;
  font-size: 18px;
  padding: 8px 5px;
}
.sm-label,
.label {
  color: #54a1cc;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  font-size: 12px;
}
.large-text {
  font-size: 30px;
}
.ghost {
  display: block;
  text-align: center;
  padding: 5px;
  background: #403e43;
  border: 1px solid #403e43;
  color: white;
  text-transform: uppercase;
  transition: .2s linear all;
}
.ghost:hover {
  background: #fcfaf7;
  color: #403e43;
}
.task-view li,
.entry-type li,
.expense-view li {
  display: inline-block;
  color: #bcbab5;
  font-size: 24px;
  padding-right: 15px;
  cursor: pointer;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  transition: .2s linear all;
}
.task-view li:hover,
.entry-type li:hover,
.expense-view li:hover {
  color: #54a1cc;
}
.task-view li.active,
.entry-type li.active,
.expense-view li.active {
  color: #403e43;
}
.entry-type li.active,
.entry-type li:hover {
  color: #54a1cc;
}
.task-range li,
.expense-range li {
  display: none;
  text-align: right;
}
.task-range li.active,
.expense-range li.active {
  display: block;
  color: #54a1cc;
}
.tasks-panel,
.view-panel,
.expense-panel {
  display: none;
}
.tasks-panel.active,
.expense-panel.active {
  display: block;
}
.notification-wrapper {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: auto;
}
.notification {
  background: #a9b500;
  border-radius: 5px;
  box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);
  padding: 10px;
}
.notification span {
  display: block;
  color: white;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.errors {
  padding: 20px;
  background-color: #ee5a66;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.errors p,
.errors li,
.errors a {
  color: white;
}
.errors ul {
  margin-left: 20px;
}
.errors li {
  list-style-type: disc;
  padding-left: 10px;
}
.errors p {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.set-value {
  overflow: hidden;
  padding-right: 30px;
}
.set-value span {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  color: black;
  white-space: nowrap;
}
.nav-panel ul {
  text-align: right;
}
.nav-panel li {
  display: inline-block;
  margin-left: 12px;
}
.nav-panel a {
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  color: #403e43;
}
.pop-down {
  display: none;
  position: fixed;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.75);
}
.pop-down.fadeOut {
  background: rgba(0, 0, 0, 0);
  bottom: auto;
}
.alert {
  display: flex;
  position: relative;
  margin: auto;
  padding: 0 20px;
  background: #403e43;
  color: #fcfaf7;
  width: 50%;
  max-width: 800px;
  height: 90px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.alert p {
  margin: auto 5px auto 0;
  color: #fcfaf7;
}
.alert .opt-1,
.alert .opt-2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  background: #fcfaf7;
  width: 90px;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  font-size: 24px;
}
.alert .opt-1 {
  right: 90px;
}
.alert .opt-2 {
  right: 0;
}
.alert.danger {
  background: #54a1cc;
}
.danger {
  color: #54a1cc !important;
}
.attachment-wrapper {
  display: none;
  position: fixed;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.75);
  align-items: center;
  justify-content: center;
}
.attachment-wrapper.active {
  display: flex;
}
.attachment {
  position: relative;
  padding: 40px 40px 80px 40px;
  background: #fcfaf7;
  width: 50%;
  max-width: 800px;
}
.attachment input[type=submit] {
  margin-left: 0;
}
.attachment input[type=submit] {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #54a1cc;
  color: #fcfaf7;
  border-bottom: none;
  cursor: pointer;
  transition: .2s linear all;
}
.attachment input[type=submit]:hover {
  background: #403e43;
}
.hide-attachment {
  position: fixed;
  font-size: 40px;
  top: 27px;
  right: 39px;
  cursor: pointer;
  color: #fcfaf7;
}
.overlay {
  position: absolute;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.overlay.active {
  display: flex;
}
.overlay-box {
  background: #fcfaf7;
  padding: 30px;
  margin: auto;
}
.overlay-close {
  position: fixed;
  top: 30px;
  left: 30px;
  color: #fcfaf7;
  font-size: 40px;
}
.create {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  border: 1px solid #54a1cc;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  color: #403e43;
}
.create i {
  display: block;
  font-size: 40px;
  padding-right: 20px;
}
.btn-link {
  display: block;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
  padding-top: 15px;
  font-size: 14px;
}
pre {
  display: block;
  font-family: monospace, serif;
  margin: 0 0 1rem;
  font-size: 12px;
  line-height: 18px;
  white-space: pre;
  white-space: pre-wrap;
  background: #eaeaea;
  color: #333;
  padding: 10px;
  overflow: scroll;
}
pre code {
  white-space: nowrap;
}
.file-upload {
  background-color: #fcfaf7;
  width: 600px;
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
}
.file-upload-btn {
  width: 100%;
  color: #54a1cc;
  background: #fcfaf7;
  border: 1px solid #54a1cc;
  padding: 10px;
  outline: none;
  text-transform: none;
  font-family: "clarendon-text-pro", serif;
  font-weight: 400;
}
.file-upload-btn:hover {
  background: #54a1cc;
  color: #fcfaf7;
  transition: all .2s ease;
  cursor: pointer;
  border-bottom: 1px solid #54a1cc;
}
.file-upload-btn:active {
  border: 0;
  outline: none;
  transition: all .2s ease;
}
.file-upload-content {
  display: none;
  text-align: center;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.image-upload-wrap {
  margin-top: 20px;
  height: 200px;
  border: 1px solid #403e43;
  position: relative;
  transition: .2s linear all;
}
.image-dropping,
.image-upload-wrap:hover {
  border: 4px solid #403e43;
}
.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}
.drag-text {
  text-align: center;
}
.drag-text h3 {
  text-transform: none;
  color: #403e43;
  width: 300px;
  max-width: 95%;
  margin: auto;
  padding: 60px 0;
}
.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}
.remove-image {
  position: relative;
  width: 200px;
  margin: auto;
  color: #fcfaf7;
  background: #54a1cc;
  border: none;
  border-bottom: none !important;
  padding: 10px 10px 10px 0;
  transition: .2s linear all;
  outline: none;
  text-transform: uppercase;
}
.remove-image i {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  bottom: 0;
  padding-right: 8px;
  background: #403e43;
  width: 50px;
}
.remove-image:hover {
  background: #54a1cc;
  transition: .2s linear all;
  cursor: pointer;
}
.remove-image:hover i {
  background: #0d0d0e;
}
.remove-image:active {
  border: none;
  transition: .2s linear all;
}
div.datedropper.toucan {
  border-radius: 3px;
  width: 157px;
}
div.datedropper.toucan .picker {
  border-radius: 3px;
  box-shadow: 0 0 11px 0px rgba(0, 0, 0, 0.09);
}
div.datedropper.toucan .pick-l {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
div.datedropper.toucan:before,
div.datedropper.toucan .pick-submit,
div.datedropper.toucan .pick-lg-b .pick-sl:before,
div.datedropper.toucan .pick-m,
div.datedropper.toucan .pick-lg-h {
  background-color: #53c4bd;
}
div.datedropper.toucan .pick-y.pick-jump,
div.datedropper.toucan .pick li span,
div.datedropper.toucan .pick-lg-b .pick-wke,
div.datedropper.toucan .pick-btn {
  color: #53c4bd;
}
div.datedropper.toucan .picker,
div.datedropper.toucan .pick-l {
  background-color: #FFF;
}
div.datedropper.toucan .picker,
div.datedropper.toucan .pick-arw,
div.datedropper.toucan .pick-l {
  color: #2cbbe7;
}
div.datedropper.toucan .pick-m,
div.datedropper.toucan .pick-m .pick-arw,
div.datedropper.toucan .pick-lg-h,
div.datedropper.toucan .pick-lg-b .pick-sl,
div.datedropper.toucan .pick-submit {
  color: #FFF;
}
div.datedropper.toucan.picker-tiny:before,
div.datedropper.toucan.picker-tiny .pick-m {
  background-color: #FFF;
}
div.datedropper.toucan.picker-tiny .pick-m,
div.datedropper.toucan.picker-tiny .pick-m .pick-arw {
  color: #2cbbe7;
}
div.datedropper.toucan.picker-lkd .pick-submit {
  background-color: #FFF;
  color: #2cbbe7;
}
@media (max-width: 1024px) {
  header {
    position: relative;
    padding-top: 0px;
    width: 100%;
    left: 0;
  }
  header h1 {
    font-size: 30px;
    text-align: center;
  }
  .rowflex {
    display: block;
  }
  .task-view,
  .entry-type,
  .expense-view {
    text-align: center;
  }
  .task-view li,
  .entry-type li,
  .expense-view li {
    font-size: 16px;
  }
  .task-range li,
  .expense-range li {
    text-align: center;
  }
  .nav-panel ul {
    text-align: center;
  }
  .nav-panel a {
    font-size: 16px;
    color: #54a1cc;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
  }
  .attachment {
    position: relative;
    padding: 15px;
    background: #fcfaf7;
    width: 100%;
    max-width: 800px;
  }
  .image-upload-wrap {
    display: none;
  }
  .tiles.four_up > li {
    width: 100% ;
  }
}
