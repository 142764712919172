body {
	background: @cream;
	padding-top: 120px;
}

header {
	position: fixed;
	align-items: center;
	justify-content: center;
	background: white;
	top: 0;
	height: 90px;
	left: 90px;
	padding: 0 20px;
	background: @cream;

	a {
		display: inline-block;
		padding: auto 5px;
	}
}

.site-dropdown {
	h1 {
		display: flex;
		align-items: center;
		justify-content: left;
		height: 90px;
		.serif;

		i {
			padding-left: 20px;
		}
	}
}

.site-list {
	display: none;
	position: absolute;
	top: 90px;
	background: @dark;
	padding: 20px;
	left: -90px;
	right: 0;
	padding-left: 20px;
	border-left: 90px solid @red;
	.box-shadow;

	a {
		color: @cream;
		.serif;
	}
}

.circle {
	display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 45px;
    right: 45px;
    height: 75px;
    width: 75px;
    background: @red;
    border-radius: 75px;
    .transition;
    .box-shadow;
    z-index: 20;

    i {
    	font-size: 50px;
    	color: white;
    }
}

.rotated {
  transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important; /* IE 9 */
  -moz-transform: rotate(45deg) !important; /* Firefox */
  -webkit-transform: rotate(45deg) !important; /* Safari and Chrome */
  -o-transform: rotate(45deg) !important; /* Opera */
}

span.plus-icon.rotated {
  transform: rotate(0) !important;
  -ms-transform: rotate(0) !important; /* IE 9 */
  -moz-transform: rotate(0) !important; /* Firefox */
  -webkit-transform: rotate(0) !important; /* Safari and Chrome */
  -o-transform: rotate(0) !important; /* Opera */
}

.circle.rotated {
	box-shadow: none;
	background: @dark;
}

.stat {
	border-right: 1px solid @dark;
	padding-left: 30px;
	padding-right: 20px;

	a {
		display: block;
		.serif;
		text-align: right;
	}
}

.stat:last-child {
	border: none;
}

.stat:first-child {
	padding-left: 0;
}

.stat-label {
	.caps;
	color: @red;
}

.stat-number {
	.serif;
	font-size: 42px;
}

.add-menu {
	display: none;
	position: fixed;
	bottom: 30px;
	right: 0;
	padding: 60px 30px 120px 20px;
	background: @dark;

	.opt-title {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: @red;
		color: @cream;
		padding: 8px 20px;
		.caps;
	}

	a {
		color: @cream;
		.serif;
	}
}

.big-icon {
	display: block;
	font-size: 24px;
	margin-top: 30px;
}

.padded {
	padding: 40px;
}

.margin-bottom {
	margin-bottom: 20px;
}

.logo {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 90px;
	width: 90px;
	top: 0;
	left: 0;
	background: @red;

	span {
		font-size: 21px;
		color: white;
	}

	img {
		width: 60%;
		margin: auto;
	}
}

.login-logo {
	.align-center;
	img {
		max-width: 40%;
		margin: auto;
	}
}

.menu {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	background: @cream;
	border-right: 5px solid @red;
	top: 0;
	height: 90px;
	width: 90px;
	right: 0;
	.transition;
	cursor: pointer;
	z-index: 2;

	i {
		font-size: 24px;
		.transition;
	}

	.open {
		display: block;
	}

	.close {
		display: none;
	}


}

.menu:hover, .menu.active {
	background: @red;

	i {
		color: @cream;
	}
}

.menu.active {
	width: 300px;
	background: @red;

	.open {
		display: none;
	}

	.close {
		display: block;
	}
}

.menu-dropdown {
	display: none;
	position: fixed;
	background: @dark;
	top: 90px;
	width: 300px;
	padding: 40px;
	right: 0;
	z-index: 2;
	.transition;

	a {
		position: relative;
		.serif;
		color: white;
		.transition;
	}

	a:hover {
		padding-left: 20px;
	}

	a:before {
		position: absolute;
		display: block;
		align-items: center;
		justify-content: center;
		left: 0;
		top: -2px;
		font-size: 24px;
		bottom: 0;
		content: "\f462";
		.icon;
		opacity: 0;
		transition: .3s linear all;
	}

	a:hover:before {
		opacity: 1;
	}
}

.menu-dropdown.active {
	display: block;
}

.flex-full {
	position: fixed;
	display: flex;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.full-wrapper {
	margin: auto;
}

h2, h3 {
	.serif;
}

.entry {
	display: none;
	margin: auto;
	max-width: 1200px;
	border-radius: 0 0 15px 15px;
	background: @cream;
	padding: 40px;
	border: 1px solid @cream;
	margin-bottom: 70px;
	.box-shadow;
	.transition;
}

.entry.active {
	display: block;
}

.task {
	margin-top: 20px;
}

.list-header {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid @light;
}

.list {
	border-bottom: 1px solid @light;
	padding-bottom: 10px;
	margin-bottom: 10px;

	a {
		.serif;
	}
}

.task-list {
	.list;

	a {
		.serif;
		color: @dark;
	}
}

.padding-top-button {
	display: block;
	.serif;
	padding-top: 30px;
}

.duration-old span {
	display: block;
	font-size: 18px;
	padding: 8px 5px;
}

.sm-label, .label {
	color: @red;
	.serif;
	font-size: 12px;
}

.large-text {
	font-size: 30px;
}

.ghost {
	display: block;
	text-align: center;
	padding: 5px;
	background: @dark;
	border: 1px solid @dark;
	color: white;
	.caps;
	.transition;
}

.ghost:hover {
	background: @cream;
	color: @dark;
}

.task-view, .entry-type, .expense-view {

	li {
		display: inline-block;
		color: @light;
		font-size: 24px;
		padding-right: 15px;
		cursor: pointer;
		.serif;
		.transition;
	}
	
	li:hover {
		color: @red;
	}

	li.active {
		color: @dark;
	}
}

.entry-type li.active, .entry-type li:hover {
	color: @red;
}

.task-range li, .expense-range li {
	display: none;
	.align-right;
}

.task-range li.active, .expense-range li.active {
	display: block;
	color: @red;
}

.tasks-panel, .view-panel, .expense-panel {
	display: none;
}

.tasks-panel.active, .expense-panel.active {
	display: block;
}

.notification-wrapper {
	display: none;
	position: fixed;
	bottom: 30px;
	right: 30px;
	width: auto;
}

.notification {
	background: @lime;
	border-radius: 5px;
	.shadow;
	padding: 10px;

	span {
		display: block;
		color: white;
		.serif;
	}
}

.errors {
	padding: 20px;
	background-color: @danger;
	.box-shadow;
	border-radius: 15px;
	p, li, a {
		color: white;
	}
	
	ul {
		margin-left: 20px;
	}

	li {
		list-style-type: disc;
		padding-left: 10px;
	}

	p {
		.serif;
	}
}

.set-value {
	overflow: hidden;
	padding-right: 30px;
	span {
		.serif;
		color: black;
		white-space: nowrap;
	}
}

.nav-panel {
	ul {
		text-align: right;
	}

	li {
		display: inline-block;
		margin-left: 12px;
	}

	a {
		.serif;
		.dark;
	}

}

.pop-down {
	display: none;
	position: fixed;
	top: -5px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(0,0,0,.75);
}

.pop-down.fadeOut {
	background: rgba(0,0,0,0);
	bottom:auto;
}

.alert {
	display: flex;
	position: relative;
	margin: auto;
	padding: 0 20px;
	background: @dark;
	color: @cream;
	width: 50%;
	max-width: 800px;
	height: 90px;
	.box-shadow;

	p {
		margin: auto 5px auto 0;
		color: @cream;
	}

	.opt-1, .opt-2 {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		bottom: 0;
		background: @cream;
		width: 90px;
		.serif;
		font-size: 24px;

	}

	.opt-1 {
		right: 90px;
	}

	.opt-2 {
		right: 0;
	}
}

.alert.danger {
	background: @red;
}

.danger {
	color: @red !important;
}

.attachment-wrapper {
	.pop-down;
	align-items: center;
	justify-content: center;
}

.attachment-wrapper.active {
	display: flex;
}

.attachment {
	position: relative;
	padding: 40px 40px 80px 40px;
	background: @cream;
	width: 50%;
	max-width: 800px;
	
	input[type=submit] {
		margin-left: 0;
	}

	input[type=submit] {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: @red;
		color: @cream;
		border-bottom: none;
		cursor: pointer;
		.transition;
	}

	input[type=submit]:hover {
		background: @dark;
	}
}

.hide-attachment {
	position: fixed;
	font-size: 40px;
	top: 27px;
	right: 39px;
	cursor: pointer;
	color: @cream;
}

.overlay {
	position: absolute;
	display: none;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0,.9);
}

.overlay.active {
	display: flex;
}

.overlay-box {
	background: @cream;
	padding: 30px;
	margin: auto;
}

.overlay-close {
	position: fixed;
	top: 30px;
	left: 30px;
	color: @cream;
	font-size: 40px;
}

.create {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
	width: 300px;
	border: 1px solid @red;
	.serif;
	color: @dark;
	
	i {
		display: block;
		font-size: 40px;
		padding-right: 20px;
	}
}

.btn-link {
	display: block;
	.serif;
	padding-top: 15px;
	font-size: 14px;
}

pre {
  display: block;
  font-family: monospace, serif;
  margin: 0 0 1rem;
  font-size: 12px;
  line-height: 18px;
  white-space: pre;
  white-space: pre-wrap;
  background: #eaeaea;
  color: #333;
  padding: 10px;
  overflow: scroll;
}

pre code {
  white-space: nowrap;
}
