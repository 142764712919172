.file-upload {
  background-color: @cream;
  width: 600px;
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-btn {
  width: 100%;
  color: @red;
  background: @cream;
  border: 1px solid @red;
  padding: 10px;
  outline: none;
  text-transform: none;
  .serif;
}

.file-upload-btn:hover {
  background: @red;
  color: @cream;
  transition: all .2s ease;
  cursor: pointer;
  border-bottom: 1px solid @red;
}

.file-upload-btn:active {
  border: 0;
  outline: none;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  height: 200px;
  border: 1px solid @dark;
  position: relative;
  .transition;
}

.image-dropping,
.image-upload-wrap:hover {
  border: 4px solid @dark;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  text-transform: none;
  color: @dark;
  width: 300px;
  max-width: 95%;
  margin: auto;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  position: relative;
  width: 200px;
  margin: auto;
  color: @cream;
  background: @red;
  border: none;
  border-bottom: none !important;
  padding: 10px 10px 10px 0;
  .transition;
  outline: none;
  text-transform: uppercase;

  i {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    bottom: 0;
    padding-right: 8px;
    background: @dark;
    width: 50px;
  }
}

.remove-image:hover {
  background: @red;
  .transition;
  cursor: pointer;

  i {
    background: darken(@dark, 20%);
  }
}

.remove-image:active {
  border: none;
  .transition;
}