
	table { 
		width: 100%; 
		border-collapse: collapse; 
		margin:50px auto;
		border: 1px solid @light; 
	}
	
	/* Zebra striping */
	tr:nth-of-type(odd) { 
		background: darken(@cream, 5%); 
	}

	th { 
		color: @dark;
		.serif;
		font-size: 13px;
	}

	td, th { 
		padding: 10px; 
		border: none; 
		text-align: left; 
		font-size: 13px;
	}

	thead tr {
		background-color: @dark;
		color: @cream;
		.caps;
		font-size: 10px !important;

		th {
			background-color: @dark;
			color: @cream !important;
			font-size: 10px !important;
			letter-spacing: 1px;
		}
	}


/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {



		table { 
		  	width: 100%; 
		}

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { border: 1px solid @light; }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid @dark; 
			position: relative;
			padding-left: 50%; 
		}

		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
			/* Label the data */
			content: attr(data-column);

			color: @dark;
			font-weight: bold;
		}

	

}