// Set Variables here:

@blue: #2cbbe7;
@purple: #2e358f;
@danger: #ee5a66; 
@red: #54a1cc;
@teal: #53c4bd;
@green: #66ba89;
@yellow: #f3ae00;
@orange: #ff880c;
@lime: #a9b500;
@dark: #403e43;
@light: #bcbab5;
@cream: #fcfaf7;

@link: @red;
@hover: darken(@red, 20%);

// Device Settings
@desktop: "(min-width: 1024px)";
@mobile: "(max-width: 1024px)";

// Set Mixins here:
.main-font {font-family: 'Oswald', sans-serif;}
.serif {font-family: "clarendon-text-pro",serif; font-weight: 400;}
.caps {text-transform: uppercase;}
.icon {font-family: "Ionicons";}

.rowflex {display: flex;}
.rowflex .columns, .rowflex .column {margin: auto;}

.blue {color: @blue;}
.teal {color: @teal;}
.purple {color: @purple;}
.red {color: @red;}
.teal {color: @teal;}
.green {color: @green;}
.yellow {color: @yellow;}
.orange {color: @orange;}
.lime {color: @lime;}
.dark {color: @dark;}
.light {color: @light;}
.white {color: white;}

.blue-bg {background: @blue;}
.teal-bg {background: @teal;}
.purple-bg {background: @purple;}
.red-bg {background: @red;}
.teal-bg {background: @teal;}
.green-bg {background: @green;}
.yellow-bg {background: @yellow;}
.orange-bg {background: @orange;}
.lime-bg {background: @lime;}
.dark-bg {background: @dark;}
.light-bg {background: @light;}
.white-bg {background: white;}

.center, .align-center {text-align: center;}
span.center {display: block;}
.align-left {text-align: left;}
.align-right {text-align: right;}
.transition {transition:.2s linear all;}
.transition-slow {transition:.5s linear all;}
.shadow {box-shadow: 0px 0px 60px -3px rgba(0, 0, 0, 0.33);}
.box-shadow {box-shadow: 0 10px 20px 0 rgba(0,0,0,0.10);}

hr.clearfix { border: none; opacity: 0;}

.hidden { 
    display: none;
}

.hidden.active {
	display: block;
}

.pointer {
    cursor: pointer;
}

.gradient-fade {
	background: -moz-linear-gradient(left, rgba(0,0,0,0.31) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-linear-gradient(left, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0.31) 0%,rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f000000', endColorstr='#00000000',GradientType=1 );
}

.sticky {
    position: sticky;
    top: 175px;
}

.flex-full {
	position: fixed;
	display: flex;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.full-wrapper {
	margin: auto;
}