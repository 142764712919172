header {
	position: relative;
	padding-top: 0px;
	width: 100%;
	left: 0;

	h1 {
		font-size: 30px;
		text-align: center;
	}
}

.rowflex {
	display: block;
}

.task-view, .entry-type, .expense-view {
	text-align: center;
	li {
		font-size: 16px;
	}
}

.task-range li, .expense-range li {
    text-align: center;
}

.nav-panel {
	ul {
		text-align: center;
	}

	a {
		font-size: 16px;
		.red;
		.caps;
		.main-font;
	}
}

.attachment {
    position: relative;
    padding: 15px;
    background: #fcfaf7;
    width: 100%;
    max-width: 800px;
}

.image-upload-wrap {
	display: none;
}

.tiles.four_up > li {
	width: 100% ;
}