div.datedropper.toucan {
  border-radius: 3px;
  width: 157px;
}
div.datedropper.toucan .picker {
  border-radius: 3px;
  box-shadow: 0 0 11px 0px rgba(0, 0, 0, 0.09);
}
div.datedropper.toucan .pick-l {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
div.datedropper.toucan:before,
div.datedropper.toucan .pick-submit,
div.datedropper.toucan .pick-lg-b .pick-sl:before,
div.datedropper.toucan .pick-m,
div.datedropper.toucan .pick-lg-h {
  background-color: @teal;
}
div.datedropper.toucan .pick-y.pick-jump,
div.datedropper.toucan .pick li span,
div.datedropper.toucan .pick-lg-b .pick-wke,
div.datedropper.toucan .pick-btn {
  color: @teal;
}
div.datedropper.toucan .picker,
div.datedropper.toucan .pick-l {
  background-color: #FFF;
}
div.datedropper.toucan .picker,
div.datedropper.toucan .pick-arw,
div.datedropper.toucan .pick-l {
  color: @blue;
}
div.datedropper.toucan .pick-m,
div.datedropper.toucan .pick-m .pick-arw,
div.datedropper.toucan .pick-lg-h,
div.datedropper.toucan .pick-lg-b .pick-sl,
div.datedropper.toucan .pick-submit {
  color: #FFF;
}
div.datedropper.toucan.picker-tiny:before,
div.datedropper.toucan.picker-tiny .pick-m {
  background-color: #FFF;
}
div.datedropper.toucan.picker-tiny .pick-m,
div.datedropper.toucan.picker-tiny .pick-m .pick-arw {
  color: @blue;
}
div.datedropper.toucan.picker-lkd .pick-submit {
  background-color: #FFF;
  color: @blue;
}
